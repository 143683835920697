<template>
    <div class="view page">
        <section class="hero is-dark is-bold">
            <div class="bg-filter"></div>
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        Configurable Pages in MARIER SKINCARE.
                    </h1>
                    <!-- <h2 class="subtitle">
                        subtitle
                    </h2> -->
                </div>
            </div>
            <div class="hero-foot">
                <nav class="tabs is-boxed is-fullwidth">
                    <div class="container">
                        <ul>
                            <li v-bind:class="{ 'is-active': $route.name === 'page-home' }">
                                <router-link to="/page/home">
                                    <span class="icon is-small">
                                        <b-icon icon="shopping"></b-icon>
                                    </span>
                                    <span>
                                        Home
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active':  ['page-news', 'add-page-news', 'edit-page-news'].includes($route.name) }">
                                <router-link to="/page/news">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="tags"></font-awesome-icon>
                                    </span>
                                    <span>
                                        News
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active':  ['page-blog', 'add-page-blog', 'edit-page-blog'].includes($route.name) }">
                                <router-link to="/page/blog">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="tags"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Blog
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': $route.name === 'page-about' }">
                                <router-link to="/page/about">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="info-circle"></font-awesome-icon>
                                    </span>
                                    <span>
                                        About Marier
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': $route.name === 'page-privacy' }">
                                <router-link to="/page/privacy">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="user-secret"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Privacy
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': $route.name === 'page-terms' }">
                                <router-link to="/page/terms">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="file-contract"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Terms and Conditions
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </section>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'Pages'
    }
</script>

<style lang="scss" scoped>
    .page {
        .hero {
            background-image: url("../assets/hero_background_3.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom;

            & > .hero-body {
                & > .container {
                    width: 100%;
                }
            }

            & > .hero-foot {
                .tabs {
                    ul {
                        margin-left: 10px;
                        margin-right: 10px;

                        li {
                            &.is-active {
                                color: #363636 !important;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
